<template>
	<p :class="classes">
		<!-- @slot Default slot for text/content in paragraph -->
		<slot />
	</p>
</template>

<script>
import { defineComponent } from "vue";

import color from "./definitions/color";
import sizes from "./definitions/sizes";

/**
 * The paragraph-component should be used instead of regular p-tag for typography sizes and rules
 * @displayName TnParagraph
 */
export default defineComponent({
	name: "TnParagraph",

	props: {
		/**
		 * Size of the text in the paragraph
		 * @values 2xs, xs, s, m, l, xl
		 */
		size: {
			type: String,
			default: "m",
			validator: (value) => {
				return sizes.includes(value.toLowerCase());
			},
		},
		/**
		 * Color of the paragraph text
		 * @deprecated
		 */
		color: {
			type: String,
			default: "black",
			validator: (value) => {
				return color.includes(value.toLowerCase());
			},
		},
		/**
		 * Applies the bold font-weight rules
		 */
		bold: {
			type: Boolean,
			default: false,
		},
		/**
		 * Apply dark theming
		 */
		dark: {
			type: Boolean,
			default: false,
		},
		/**
		 * Apply a more subtle effect on the text-color
		 */
		gray: {
			type: Boolean,
			default: false,
		},
		/**
		 * Responsive will shrink font-size one size down on mobile
		 */
		responsive: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		classes() {
			const typography = this.size ? `text-${this.size}` : "text-m";
			return [
				"tn-paragraph",
				typography,
				{ gray: this.gray || this.color === "gray" },
				{ bold: this.bold },
				{ dark: this.dark || this.color === "white" },
				{ responsive: this.responsive },
			];
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/typography/scss/mixins" as mixins;
@use "@/assets/typography/scss/responsive" as responsive;
@use "@/assets/scss/variables" as variables;

.tn-paragraph {
	--text-color: #{variables.$color-neutrals-800-shade};
	--link-color: #{variables.$color-cta-default};

	font-family: variables.$font-family-telenor-base;
	font-weight: normal;
	text-decoration: none;
	word-break: break-word;
	margin: 0;
	padding: 0;
	color: var(--text-color);

	:deep(a) {
		color: var(--link-color);
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	&.gray {
		--text-color: #{variables.$color-neutrals-700-shade};
	}

	&.dark {
		--text-color: #{variables.$color-neutrals-white};
		--link-color: #{variables.$color-cta-dark-default};

		&.gray {
			--text-color: #{variables.$color-neutrals-200-tint};
		}
	}

	/* Sizes */
	&.text-2xs {
		@include mixins.font-text-2xs;

		&.bold {
			@include mixins.font-text-bold-2xs;
		}

		&.responsive {
			@include responsive.font-text-2xs;

			&.bold {
				@include responsive.font-text-bold-2xs;
			}
		}
	}

	&.text-xs {
		@include mixins.font-text-xs;

		&.bold {
			@include mixins.font-text-bold-xs;
		}

		&.responsive {
			@include responsive.font-text-xs;

			&.bold {
				@include responsive.font-text-bold-xs;
			}
		}
	}

	&.text-s {
		@include mixins.font-text-s;

		&.bold {
			@include mixins.font-text-bold-s;
		}

		&.responsive {
			@include responsive.font-text-s;

			&.bold {
				@include responsive.font-text-bold-s;
			}
		}
	}

	&.text-m {
		@include mixins.font-text-m;

		&.bold {
			@include mixins.font-text-bold-m;
		}

		&.responsive {
			@include responsive.font-text-m;

			&.bold {
				@include responsive.font-text-bold-m;
			}
		}
	}

	&.text-l {
		@include mixins.font-text-l;

		&.bold {
			@include mixins.font-text-bold-l;
		}

		&.responsive {
			@include responsive.font-text-l;

			&.bold {
				@include responsive.font-text-bold-l;
			}
		}
	}

	&.text-xl {
		@include mixins.font-text-xl;

		&.bold {
			@include mixins.font-text-bold-xl;
		}

		&.responsive {
			@include responsive.font-text-xl;

			&.bold {
				@include responsive.font-text-bold-xl;
			}
		}
	}
}
</style>
